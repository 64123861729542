/* .editorDivContainer { */
.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.editor-scroller {
  height: auto;
  overflow: auto;
  /* resize: vertical; */
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

h1 {
  font-size: 24px;
  color: #333;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  margin: 0px 20px 20px 3%;
  border-radius: 2px;
  /* max-width: 600px; */
  color: #000;
  position: relative;
  line-height: 23px;
  font-weight: 400;
  text-align: left;
  height: fit-content;
}

@media (max-width: 898px) {
  .editor-container {
    margin: 0px 0px 20px 0;
  }
}

.editor-inner {
  background: #fff;
  position: relative;
  height: 373px;
  overflow-y: auto !important;
  overflow-x: hidden;
  border-radius: 4px !important;
}

.editor-input {
  /* height: 100%; */
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding-right: 10px;
  /* padding: 15px 10px; */
  caret-color: #444;
  padding: 1.5em;
  padding-right: 25px;
}

.editor-placeholder {
  color: #6c7985;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 22px;
  left: 22px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic !important;
}

.editor-text-underline {
  text-decoration: underline !important;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 10px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 30px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 27px;
}

.editor-heading-h3 {
  font-size: 24px;
}

.editor-heading-h4 {
  font-size: 21px;
}

.editor-heading-h5 {
  font-size: 19px;
}

.editor-heading-h6 {
  font-size: 16px;
}

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

/* .emoji.happysmile { */
/* //   background-image: url(./images/emoji/1F642.png); */
/* } */

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

#block-controls span.block-type.paragraph {
  background-image: url(../../assets/lexical-icons/text-paragraph.svg);
}

#block-controls span.block-type.h1 {
  background-image: url(../../assets/lexical-icons/type-h1.svg);
}

#block-controls span.block-type.h2 {
  background-image: url(../../assets/lexical-icons/type-h2.svg);
}

#block-controls span.block-type.quote {
  background-image: url(../../assets/lexical-icons/chat-square-quote.svg);
}

#block-controls span.block-type.ul {
  background-image: url(../../assets/lexical-icons/list-ul.svg);
}

#block-controls span.block-type.ol {
  background-image: url(../../assets/lexical-icons/list-ol.svg);
}

#block-controls span.block-type.code {
  background-image: url(../../assets/lexical-icons/code.svg);
}

.box-style {
  /* height: auto !important; */
  width: 100% !important;
  z-index: 5;
  background-color: #fff;
  border-radius: 0.25rem;
}

.editor-layoutContainer {
  display: grid;
  gap: 10px;
  margin: 10px 0;
}

/* 
.editor-layoutItem::hover {
  background: red;
  color: #fff;
} */

.editor-layoutItem {
  border: 1px dashed #ddd;
  padding: 8px 16px;
}

.editor-tableAddRows:hover {
  background-color: #c9dbf0;
}

.editor-tableAddRows {
  position: absolute;
  width: calc(100% - 25px);
  background-color: #eee;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}

.editor-tableAddRows:after {
  background-image: url(../../assets/lexical-icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}