.editor-text-italic {
  font-style: italic !important;
}

.editor-text-underline {
  text-decoration: underline !important;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.link-editor div.link-edit {
  background-image: url(../../assets/lexical-icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-trash {
  background-image: url(../../assets/lexical-icons/trash.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 11px;
  height: 14px;
  bottom: 0;
  right: 0;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABJ0AAASdAHeZh94AAACIUlEQVR4Xu2WvUsCYRzHqyFUHA4x4QIj4jyHcGsQAuuMXly04WhraA1ElP6AXC9aBJGW1lpcXFt829yCIGhwCSMKGorsRX36/Y5HuvQePYWnyYMHOX2e7+f37jM1NXkmEeAcgUQioQGig0uSpLtarbbCGfkrH4vF8vBGjEsQBFKv1xe5GxGNRi974d13TdOOugbM8LAE4GeFQmGPpW2z2d54cHVNgB/CR5vlvcfjeeQGp8ItWnR/co8G2e32ZrVa9fE04JsFdzgczUqlIvGGm4Ye4IQbHHI+B16h54PgMhfPAX4MwsycU8/55BzgOOHQa5xyfQUH8DaEfYGX58lBrQbwFsDnecEDQ+BYcCIXuKHP+0KOaaA5X+IJ/2JNOApf5gl/GTDhSKlUWmXB4/F4Ev4Zz/P5vDCugbu03czGKykWi+ssYb/ff03PYru2s9ns2jhGNM1CD7Md4RssQa/XW6cFi+1qXCPZ8MToc1IulzdZSqIo3tOUGecEGtFKp9OqVQuYcPB8hxAybSbkcrmeGcWqG2AV3jArOqx2gG+z4LIs3zLgGIm2qqonVgxAzz57hRAOYVdYAnDXcwyAdyKRyIUVOO4JwfowFE4H4K8Anx0mAPvwumXsFv1GHAqFroad7f39Hb7AwdMCUZwBlp5MJrNvKD497MFgsGjpsHFTOBwOOJ3OhqIop6MezuVyWz6f78btdj+kUqmDUc9P9v9rBH4A7Tw9MPGme6kAAAAASUVORK5CYII=');
  background-position: bottom right;
  background-position: center;
  background-size: cover;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  z-index: 5;
}

.editor-tableCell {
  border: none !important;
  width: 100%;
}

.editor-table {
  margin: 0 !important;
  margin-bottom: 9px !important;
  border: none !important;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  z-index: 6;
}

.editor-tableCell:not(:has(img)) {
  padding-right: 3em;
}

.editor-tableCell:has(img) {
  padding-right: 10px;
}